import React from 'react';

import './PortfolioItem.css';
import Button from '../../Button/Button';
import { PHONE_NUMBER } from '../../constants';
import ImageGallery from "react-image-gallery";

const images = [
  {
    original: "/assets/images/portfolio/1/1_mob.jpg",
    thumbnail: "/assets/images/portfolio/1/1_mob.jpg",
    loading: "lazy",
    originalAlt: "Завершенный проект “Timekeeper”",
    thumbnailAlt: "Завершенный проект “Timekeeper”",
    originalTitle: "Завершенный проект “Timekeeper”",
    thumbnailTitle: "Завершенный проект “Timekeeper”",
  },
  {
    original: "/assets/images/portfolio/1/1_mob.jpg",
    thumbnail: "/assets/images/portfolio/1/1_mob.jpg",
    loading: "lazy",
    originalAlt: "Завершенный проект “Timekeeper”",
    thumbnailAlt: "Завершенный проект “Timekeeper”",
    originalTitle: "Завершенный проект “Timekeeper”",
    thumbnailTitle: "Завершенный проект “Timekeeper”",
  },
  {
    original: "/assets/images/portfolio/1/1_mob.jpg",
    thumbnail: "/assets/images/portfolio/1/1_mob.jpg",
    loading: "lazy",
    originalAlt: "Завершенный проект “Timekeeper”",
    thumbnailAlt: "Завершенный проект “Timekeeper”",
    originalTitle: "Завершенный проект “Timekeeper”",
    thumbnailTitle: "Завершенный проект “Timekeeper”",
  },
  {
    original: "/assets/images/portfolio/1/1_mob.jpg",
    thumbnail: "/assets/images/portfolio/1/1_mob.jpg",
    loading: "lazy",
    originalAlt: "Завершенный проект “Timekeeper”",
    thumbnailAlt: "Завершенный проект “Timekeeper”",
    originalTitle: "Завершенный проект “Timekeeper”",
    thumbnailTitle: "Завершенный проект “Timekeeper”",
  },
  {
    original: "/assets/images/portfolio/1/1_mob.jpg",
    thumbnail: "/assets/images/portfolio/1/1_mob.jpg",
    loading: "lazy",
    originalAlt: "Завершенный проект “Timekeeper”",
    thumbnailAlt: "Завершенный проект “Timekeeper”",
    originalTitle: "Завершенный проект “Timekeeper”",
    thumbnailTitle: "Завершенный проект “Timekeeper”",
  },
  {
    original: "/assets/images/portfolio/1/1_mob.jpg",
    thumbnail: "/assets/images/portfolio/1/1_mob.jpg",
    loading: "lazy",
    originalAlt: "Завершенный проект “Timekeeper”",
    thumbnailAlt: "Завершенный проект “Timekeeper”",
    originalTitle: "Завершенный проект “Timekeeper”",
    thumbnailTitle: "Завершенный проект “Timekeeper”",
  },
  {
    original: "/assets/images/portfolio/1/1_mob.jpg",
    thumbnail: "/assets/images/portfolio/1/1_mob.jpg",
    loading: "lazy",
    originalAlt: "Завершенный проект “Timekeeper”",
    thumbnailAlt: "Завершенный проект “Timekeeper”",
    originalTitle: "Завершенный проект “Timekeeper”",
    thumbnailTitle: "Завершенный проект “Timekeeper”",
  },
  {
    original: "/assets/images/portfolio/1/1_mob.jpg",
    thumbnail: "/assets/images/portfolio/1/1_mob.jpg",
    loading: "lazy",
    originalAlt: "Завершенный проект “Timekeeper”",
    thumbnailAlt: "Завершенный проект “Timekeeper”",
    originalTitle: "Завершенный проект “Timekeeper”",
    thumbnailTitle: "Завершенный проект “Timekeeper”",
  },
  {
    original: "/assets/images/portfolio/1/1_mob.jpg",
    thumbnail: "/assets/images/portfolio/1/1_mob.jpg",
    loading: "lazy",
    originalAlt: "Завершенный проект “Timekeeper”",
    thumbnailAlt: "Завершенный проект “Timekeeper”",
    originalTitle: "Завершенный проект “Timekeeper”",
    thumbnailTitle: "Завершенный проект “Timekeeper”",
  },
];

const PortfolioItem = ({ }) => {

  return (
    <>
      <section class="portfolio-item">
        <ImageGallery items={images}  showPlayButton={false} additionalClass="portfolio-item_image" />

        <div className="portfolio-item_info">
          <h1 className="portfolio-item_title">Завершенный <br /> проект “Timekeeper”</h1>
          <p> Команда Tech Global Industries завершила очередной проект TIMEKEEPER и готова провести небольшой обзор.</p>
          <p>Особенности, выполненные в данном проекте - это вентиляционная система регулируемая по зонам,Также с офиса идет 2 вида вещания это развлекательное и вещание для официантов, которое сообщает о поступлении и готовности заказов а также управление аудио вещанием!</p>
          <p>Доверьте монтаж электрики опытным специалистам из Tech Global Industries !</p>

          <div className="portfolio-item_buttons">
            <Button text="Связаться с нами" href={PHONE_NUMBER} />
            <Button text="Узнать цены" style="btn--secondary" href="/#calculator" />
          </div>
        </div>

      </section>

      <section className="feedback">
        <h3 className="feedback_header">
          <span className='feedback-span orange-text'>
            Отзывы
          </span>
          наших клиентов
        </h3>

        <div className="feedback_wrapper">
          <div className="feedback-item">
            <div className="feedback-item_photo">
              <img src="/assets/images/clients/1.jpg" alt="" />
            </div>
            <h5 className="feedback-item_name">Cтепан Степанов</h5>
            <span className="feedback-item_position">Директор</span>
            <p className="feedback-item_text">Всё очень хорошо, мне понравилась
              работа компании TechGlobal</p>
            <div className="feedback-item_stars">
              <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.6153 0L15.3958 8.55756H24.3938L17.1143 13.8464L19.8948 22.404L12.6153 17.1151L5.33583 22.404L8.11635 13.8464L0.836859 8.55756H9.83481L12.6153 0Z" fill="#DFE301" />
              </svg><svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.6153 0L15.3958 8.55756H24.3938L17.1143 13.8464L19.8948 22.404L12.6153 17.1151L5.33583 22.404L8.11635 13.8464L0.836859 8.55756H9.83481L12.6153 0Z" fill="#DFE301" />
              </svg>
              <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.6153 0L15.3958 8.55756H24.3938L17.1143 13.8464L19.8948 22.404L12.6153 17.1151L5.33583 22.404L8.11635 13.8464L0.836859 8.55756H9.83481L12.6153 0Z" fill="#DFE301" />
              </svg>
              <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.6153 0L15.3958 8.55756H24.3938L17.1143 13.8464L19.8948 22.404L12.6153 17.1151L5.33583 22.404L8.11635 13.8464L0.836859 8.55756H9.83481L12.6153 0Z" fill="#DFE301" />
              </svg>
              <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.6153 0L15.3958 8.55756H24.3938L17.1143 13.8464L19.8948 22.404L12.6153 17.1151L5.33583 22.404L8.11635 13.8464L0.836859 8.55756H9.83481L12.6153 0Z" fill="#DFE301" />
              </svg>
            </div>
          </div>
          <div className="feedback-item">
            <div className="feedback-item_photo">
              <img src="/assets/images/clients/2.jpg" alt="" />
            </div>
            <h5 className="feedback-item_name">Александр Фернандо</h5>
            <span className="feedback-item_position">Дизайнер Интерьера</span>
            <p className="feedback-item_text">Все очень круто прям, на высшем уровне
отработали ребята из TechGlobal</p>
            <div className="feedback-item_stars">
              <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.6153 0L15.3958 8.55756H24.3938L17.1143 13.8464L19.8948 22.404L12.6153 17.1151L5.33583 22.404L8.11635 13.8464L0.836859 8.55756H9.83481L12.6153 0Z" fill="#DFE301" />
              </svg><svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.6153 0L15.3958 8.55756H24.3938L17.1143 13.8464L19.8948 22.404L12.6153 17.1151L5.33583 22.404L8.11635 13.8464L0.836859 8.55756H9.83481L12.6153 0Z" fill="#DFE301" />
              </svg>
              <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.6153 0L15.3958 8.55756H24.3938L17.1143 13.8464L19.8948 22.404L12.6153 17.1151L5.33583 22.404L8.11635 13.8464L0.836859 8.55756H9.83481L12.6153 0Z" fill="#DFE301" />
              </svg>
              <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.6153 0L15.3958 8.55756H24.3938L17.1143 13.8464L19.8948 22.404L12.6153 17.1151L5.33583 22.404L8.11635 13.8464L0.836859 8.55756H9.83481L12.6153 0Z" fill="#DFE301" />
              </svg>
              <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.6153 0L15.3958 8.55756H24.3938L17.1143 13.8464L19.8948 22.404L12.6153 17.1151L5.33583 22.404L8.11635 13.8464L0.836859 8.55756H9.83481L12.6153 0Z" fill="#DFE301" />
              </svg>
            </div>
          </div>
          <div className="feedback-item">
            <div className="feedback-item_photo">
              <img src="/assets/images/clients/3.jpg" alt="" />
            </div>
            <h5 className="feedback-item_name">Давид Валов</h5>
            <span className="feedback-item_position">Прораб</span>
            <p className="feedback-item_text">Все быстро и качественно. Особенно электропроводка</p>
            <div className="feedback-item_stars">
              <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.6153 0L15.3958 8.55756H24.3938L17.1143 13.8464L19.8948 22.404L12.6153 17.1151L5.33583 22.404L8.11635 13.8464L0.836859 8.55756H9.83481L12.6153 0Z" fill="#DFE301" />
              </svg><svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.6153 0L15.3958 8.55756H24.3938L17.1143 13.8464L19.8948 22.404L12.6153 17.1151L5.33583 22.404L8.11635 13.8464L0.836859 8.55756H9.83481L12.6153 0Z" fill="#DFE301" />
              </svg>
              <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.6153 0L15.3958 8.55756H24.3938L17.1143 13.8464L19.8948 22.404L12.6153 17.1151L5.33583 22.404L8.11635 13.8464L0.836859 8.55756H9.83481L12.6153 0Z" fill="#DFE301" />
              </svg>
              <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.6153 0L15.3958 8.55756H24.3938L17.1143 13.8464L19.8948 22.404L12.6153 17.1151L5.33583 22.404L8.11635 13.8464L0.836859 8.55756H9.83481L12.6153 0Z" fill="#DFE301" />
              </svg>
              <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.6153 0L15.3958 8.55756H24.3938L17.1143 13.8464L19.8948 22.404L12.6153 17.1151L5.33583 22.404L8.11635 13.8464L0.836859 8.55756H9.83481L12.6153 0Z" fill="#DFE301" />
              </svg>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PortfolioItem;
