import React from 'react';
import './Footer.css';
import Logo from '../Logo/Logo';
import { FACEBOOK, INSTAGRAM } from '../constants';

function Footer() {
  return (
    <footer id="footer" className="footer">
      <div className="footer_wrapper">
        <div className="footer_left">
          <div className="footer_logo">
            <Logo />
          </div>
          <ul className="footer_links">
            <a className="footer_link" href="/#about">О нас</a>
            <a className="footer_link" href="/#portfolio">Наши проекты</a>
            <a className="footer_link" href="/#our-services">Услуги</a>
            <a className="footer_link" href="/#calculator">Цены</a>
          </ul>

          <div className="footer_social">
            <a href={FACEBOOK} target='_blank'>
              <img src="/assets/svg/icon-fb.svg" alt="" />
            </a>
            <a href={INSTAGRAM} target='_blank'>
              <img src="/assets/svg/icon-instagram.svg" alt="" />
            </a>
          </div>
        </div>

        <div className="footer_right">
          <iframe className="footer_map" src="https://yandex.uz/map-widget/v1/?ll=69.300024%2C41.318207&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgoxNTIyNTM2MDI5EjBPyrt6YmVraXN0b24sIFRvc2hrZW50LCBUYW1hcmF4b25pbSBrb8q7Y2hhc2ksIDgiCg2kmYpCFbxFJUI%2C&tab=inside&z=19.33" width="100%" height="225" frameborder="0" allowfullscreen="true"></iframe>
          
        <p className="footer_copyright">© Copyright 2024, All Rights Reserved</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
