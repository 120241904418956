import React, { useState } from 'react';
import './Calculator.css';
import Сonsultation from '../Сonsultation/Сonsultation';
import Button from '../Button/Button';

const Calculator = () => {
  const [area, setArea] = useState(1);
  const [price, setPrice] = useState(20);
  const [totalCost, setTotalCost] = useState(area * price);

  const handleAreaChange = (e) => {
    const value = e.target.value;

    if (/^\d*$/.test(value)) {
      const newArea = Number(value);
      setArea(newArea);
      setTotalCost(newArea * price);
    }
  };

  const formatCost = (cost) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    }).format(cost);
  };

  return (
    <section id="calculator" className="calculator">
      <div className="calculator_wrapper">
        <h3 className="calculator_header">
          Калькулятор для расчета стоимости
          <br />
          <span className="calculator-span orange-text">услуг</span>
        </h3>
        <p className="calculator_text">
          Мы создали калькулятор для расчета стоимости наших услуг исходя из параметров вашего помещения
        </p>
        <Сonsultation />
      </div>

      <div className="calculator_form">
        <div className="calculator_block">
          <div className="calculator_field">
            <label className="calculator_label" htmlFor="area">Квадратура помещения (m²)</label>
            <input
              className="calculator_input"
              id="area"
              type="text"
              value={area}
              onChange={handleAreaChange}
              placeholder="Введите площадь"
            />
          </div>

          <div className="calculator_field">
            <label className="calculator_label" htmlFor="cost">Приблизительная стоимость</label>
            <input
              className="calculator_input"
              id="cost"
              type="text"
              value={formatCost(totalCost)}
              disabled
            />
          </div>
        </div>

        <Button text="Рассчитать стоимость" style="btn--large" />
      </div>
    </section>
  );
};

export default Calculator;
