import React, { useEffect, useState } from 'react';
import './Our-services.css';
import Button from '../Button/Button';

const categories = [
  'Все',
  'Монтаж эл.систем',
  'Сборка и подключение ТП',
  'Системы безопасности',
  'Проектирование'
];

const OurServices = () => {
  const [services, setServices] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('Все');

  useEffect(() => {
    fetch("./services.json")
      .then(response => response.json())
      .then(data => setServices(data))
      .catch(error => console.error("Error loading services:", error));
  }, []);

  const filteredServices = selectedCategory === 'Все'
    ? services
    : services.filter(service =>
        service.category?.includes(selectedCategory)
      );

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  return (
    <section id="our-services" className="our-services">
      <div className="our-services_wrapper">
        <h3 className="our-services_header">
          <span className="our-services-span orange-text">
            Наши услуги:
          </span><br />
          Электромонтаж и<br /> проектирование
        </h3>
        <p className="our-services_text">
          Мы уверены, что наш опыт, знания и профессионализм позволят нам успешно реализовать ещё множество проектов и внести значительный вклад в развитие инфраструктуры различных объектов.
        </p>
      </div>

      <div className="our-services_filter">
        {categories.map(category => (
          <div
            key={category}
            className={`our-services_filter_tab ${
              selectedCategory === category ? 'our-services_filter_tab--active' : ''
            }`}
            onClick={() => handleCategoryClick(category)}
          >
            {category}
          </div>
        ))}
      </div>

      <div className="our-services_cards">
        {filteredServices.length > 0 ? (
          filteredServices.map((service, index) => (
            <div key={index} className="our-services_card">
              <picture className="our-services_card_image">
                <source srcSet={service.imageUrlMob} media="(max-width: 768px)" />
                <source srcSet={service.imageUrl} media="(min-width: 769px)" />
                <img src={service.imageUrl} alt={service.serviceName} />
              </picture>
              <h4 className="our-services_card_header">
                {service.serviceName}
              </h4>
              <div className="our-services_card_btn">
                <Button text="Рассчитать стоимость" href="#calculator" />
              </div>
            </div>
          ))
        ) : (
          <p>Услуги не найдены по выбранной категории.</p>
        )}
      </div>
    </section>
  );
};

export default OurServices;
