import React from 'react';
import './SmartHome.css';
import Button from '../Button/Button';
import { PHONE_NUMBER } from '../constants';

const SmartHome = () => {

  return (
    <section id="smarthome" className="smarthome">
      <div className="smarthome_wrapper">
        <h2 className="smarthome_header">
          Установка и автоматизация <br/>
          <span className='smarthome-span orange-text'>
            “Системы умный дом”
          </span>
        </h2>
        <p className="smarthome_text">Мы предлагаем для каждого нашего клиента эксклюзивные и самые оптимальные решения автоматизации, где каждая деталь тщательно продумана</p>
        
        <Button text="Связаться с нами" href={PHONE_NUMBER} />
      </div>
      <div className="smarthome_image">
        <img src="/assets/images/smart-home.jpg" alt="" />
      </div>
    </section>
  );
}

export default SmartHome;
