import React from 'react';
import './Logo.css';

const Logo = () => {

  return (
    <img src="/assets/logo.png" alt="Tech global logo" />
  );
}

export default Logo;
