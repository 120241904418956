import React from 'react';
import './Сonsultation.css';
import Logo from '../Logo/Logo';
import Button from '../Button/Button';
import { TELEGRAM } from '../constants';

const Сonsultation = () => {

  return (
    <div className="consultation">
      <div className="consultation-logo">
        <Logo />
      </div>
      
      <div className="consultation-wrapper">
        <h5 className="consultation-header">Онлайн-консультация</h5>
        <p className="consultation-text">Задайте интересующий вас вопрос,<br/>мы отвечаем 24 / 7</p>
      </div>
      
      <Button text="Написать" href={TELEGRAM} target="_blank" />
    </div>
  );
}

export default Сonsultation;
