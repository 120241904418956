import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';

import Footer from './Footer/Footer';
import Header from './Header/Header';
import HomePage from './pages/HomePage/HomePage';
import PortfolioItem from './Portfolio/PortfolioItem/PortfolioItem';
import ScrollToTop from './ScrollToTop/ScrollToTop';

function App() {
  return (
    <div className="App">
      <Router>
        <Header />

        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/portfolio/:id" element={<PortfolioItem />} />
        </Routes>

        <Footer />
      </Router>
    </div>
  );
}

export default App;
