import React from 'react';
import './About.css';
import Button from '../Button/Button';
import { PHONE_NUMBER, SITE } from '../constants';
import Сonsultation from '../Сonsultation/Сonsultation';

function About() {
  return (
    <section id="about" className="about">
      <div className="about_wrapper">
        <h1 className="about_header">Moнтаж и проектирование электроснабжения<br></br>
          <span className='about-span orange-text'>
            любой сложности
          </span>
        </h1>
        <div className="about_text">
          <p>Мы осуществляем электротехническое проектирование объектов любой сложности согласно нормативам.</p>
          <p>Целью проектирования является грамотное распределение ресурсов и получение предварительной сметной и исполнительной документации.</p>
          <p>Все это заложено в стоимость услуг. А также прилагается гарантия на 1 календарный год со сдачи объекта.</p>
        </div>

        <div className="about_buttons">
          <Button text="Связаться с нами" href={PHONE_NUMBER} />
          <Button text="Узнать цены" style="btn--secondary" href="#calculator" />
        </div>

        <Сonsultation />
      </div>

      <div className="about_images">
        <div className="about_image about_image-1">
          <div className="about_arrow-up">
            <img src="/assets/svg/icon-arrow-up.svg" alt="" />
          </div>
          <img src="/assets/images/about-1.png" alt="" />
        </div>

        <div className="about_image about_image-2">
          <img src="/assets/images/about-2.jpg" alt="" />
        </div>

        <div className="about_image about_image-3">
          <div className="about_site">{SITE}</div>
          <img src="/assets/images/about-3.png" alt="" />
        </div>
      </div>

    </section>
  );
}

export default About;
