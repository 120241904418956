import React from 'react';
import './Process.css';
import Logo from '../Logo/Logo';

const Process = () => {

  return (
    <section id="process" className='process'>
      <h3 className="process_header">Процесс 
        <span className='process-span orange-text'>
          нашей 
        </span>
        работы
      </h3>
      <p className="process_text">В процессе выполнения проектов мы учитываем индивидуальные особенности каждого объекта, а также требования и пожелания заказчиков. Благодаря этому наши решения являются уникальными и адаптированными под конкретные нужды клиента.</p>

      <div className="process_logo">
        <Logo />
      </div>

      <div className="process_wrapper">

        <div className="process_image">
          <img src="/assets/images/process.jpg" alt="" />
        </div>
        <div className="process_steps">
          <div className="process_step">
            <div className="process_step_icon">
              <img src="/assets/svg/icon-message.svg" alt="" />
            </div>
            <h5 className="process_step_header">Заявка</h5>
            <ol className="process_step_text">
              <li>
                <span className="process_step_number">1)</span>
                Заявка (Выявление потребностей, подбор индивидуального решения)</li>
              <li>
                <span className="process_step_number">2)</span>
                Предварительный расчет, заключение договора.
              </li>
              <li>
                <span className="process_step_number">3)</span>
                Проектирование электротехнической документации и согласование
              </li>
            </ol>
          </div>

          <div className="process_step process_step--active">
            <div className="process_step_icon">
              <img src="/assets/svg/icon-installation.svg" alt="" />
            </div>
            <h5 className="process_step_header">Электро-монтаж</h5>
            <ol className="process_step_text">
              <li>
                <span className="process_step_number">4)</span>
                (Выполнение грязных работ. Прокладка кабелей, штробление, монтаж подразетников, сборка щита)
              </li>
            </ol>
          </div>

          <div className="process_step">
            <div className="process_step_icon">
              <img src="/assets/svg/icon-done.svg" alt="" />
            </div>
            <h5 className="process_step_header">Чистовые работы / Сдача проекта</h5>
            <ol className="process_step_text">
              <li>
                <span className="process_step_number">5)</span>
                Чистовые работы (Установка конечных механизмов, подключение, настройка запуск и пусконаладка объекта.)
              </li>
              <li>
                <span className="process_step_number">6)</span>
                Сдача объекта. (Подтверждение объемов, сдача исполнительной документации по ГОСТ, альбом с инженерными проектами)
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Process;
