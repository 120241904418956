import React from 'react';
import Calculator from "../../Calculator/Calculator";
import Portfolio from "../../Portfolio/Portfolio";
import Process from "../../Process/Process";
import About from "../../About/About";
import SmartHome from "../../SmartHome/SmartHome";
import OurServices from '../../Our services/Our-services';


const HomePage = () =>  {
  return (
    <>
      <About />
      <Portfolio />
      <SmartHome />
      <Process />
      <OurServices />
      <Calculator />
    </>
  );
}

export default HomePage;
