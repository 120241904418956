import React from 'react';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';
import Logo from '../Logo/Logo';

function Header() {
  const { pathname, hash } = useLocation();
  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        const yOffset = -60;
        const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
  
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  }, [pathname, hash]);

  return (
    <header id="header" className="header">
      <div className="header_logo">
        <Logo />
      </div>
      <ul className="nav_list">
        <Link className="nav_link" to="/#about">О нас</Link>
        <Link className="nav_link" to="/#portfolio">Наши проекты</Link>
        <Link className="nav_link" to="/#our-services">Услуги</Link>
        <Link className="nav_link" to="/#calculator">Цены</Link>
      </ul>
    </header>
  );
}

export default Header;
