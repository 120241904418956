import React from 'react';
import './Button.css';

const Button = ({ text, style="btn--primary", href, target }) => {

  return (
    <a className={`btn ${style}`} href={href} target={target}>{text}</a>
  );
}

export default Button;
